"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultTheme = exports.ThemeVersionOrigin = exports.ThemeName = void 0;
var ThemeName;
(function (ThemeName) {
    ThemeName["Barebones"] = "barebones";
    ThemeName["Booklike"] = "booklike";
})(ThemeName || (exports.ThemeName = ThemeName = {}));
var ThemeVersionOrigin;
(function (ThemeVersionOrigin) {
    ThemeVersionOrigin["ThemeCreated"] = "themeCreated";
})(ThemeVersionOrigin || (exports.ThemeVersionOrigin = ThemeVersionOrigin = {}));
exports.DefaultTheme = ThemeName.Booklike;
