"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanId = exports.PlanName = void 0;
var PlanName;
(function (PlanName) {
    PlanName["Essential"] = "Essential";
    PlanName["Startup"] = "Startup";
    PlanName["Pro"] = "Pro";
    PlanName["Business"] = "Business";
    PlanName["Custom"] = "Custom";
    PlanName["PayAsYouGrow"] = "Pay as you grow";
})(PlanName || (exports.PlanName = PlanName = {}));
var PlanId;
(function (PlanId) {
    PlanId["PayAsYouGrow"] = "pay_as_you_grow";
})(PlanId || (exports.PlanId = PlanId = {}));
