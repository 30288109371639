import classNames from 'classnames'
import React from 'react'

import './BottomDrawerNotch.scss'

const BottomDrawerNotch = ({ className, ...rest }: React.ComponentProps<'div'>) => {
  return (
    <div className={classNames('bottom-drawer__notch', className)} aria-label="notch" {...rest}>
      <div className="bottom-drawer__notch-handle" />
    </div>
  )
}

export default BottomDrawerNotch
