import Konva from 'konva'

import { Image, getBoundaries } from 'common/drawing'

import { ImageNode } from '../types'
import Part from './Part'

export default class ImagePart extends Part {
  private imageDrawing: Image

  constructor(config: Konva.ImageConfig) {
    super(config)

    this.imageDrawing = new Image()
  }

  override cache(config: {
    x?: number
    y?: number
    width?: number
    height?: number
    drawBorder?: boolean
    offset?: number
    pixelRatio?: number
    imageSmoothingEnabled?: boolean
    hitCanvasPixelRatio?: number
  }) {
    super.cache(config)

    if (this._cache.has('canvas') && !this.getAttr('nodeData').editable) this.drawHitFromCache()

    return this
  }

  public override getBoundaries() {
    let boundaries = getBoundaries(this.imageDrawing.toCanvas())
    if (!boundaries?.width && !boundaries?.height) {
      //Image could have all transparent pixel,
      //assign 1x1 pixel boundaries to force cache canvas creation
      boundaries.width = 1
      boundaries.height = 1
    }
    return boundaries
  }

  public override async render(node: ImageNode) {
    super.render(node)

    if (node.position) {
      await this.imageDrawing.build({ ...node.image, ...node })
      const imageResource = this.imageDrawing.toImage()
      this.width(imageResource.width)
      this.height(imageResource.height)
      this.scale({ x: 1, y: 1 })
      this.offsetX(imageResource.width / 2)
      this.offsetY(imageResource.height / 2)
      this.x(node.position.x * node.scale)
      this.y(node.position.y * node.scale)
      this.rotation(node.position.rotation ?? 0)
      this.image(imageResource)
    } else {
      // Prevent part from being larger than Stage because this could imply going over
      // the browser maximum canvas size. Stage is already constrained to breakpoint according to browser limits.
      // TODO: maximum canvas size should be handled indepedently and not rely on stage size being set correctly?
      const stage = this.getStage()

      await this.imageDrawing.build({
        ...node.image,
        ...node,
        boundingBox: stage ? { width: stage.width(), height: stage.height() } : undefined,
        scale: 1 / stage.getUnzoomedScale(),
        allowOversize: false,
      })
      const imageResource = this.imageDrawing.toImage()
      this.width(imageResource.width)
      this.height(imageResource.height)

      this.image(imageResource)
    }
  }
}
