import classNames from 'classnames'
import React from 'react'

import './BottomDrawerContent.scss'

const BottomDrawerContent = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ className, children, ...rest }, ref) => {
    return (
      <div className={classNames('bottom-drawer__content', className)} {...rest}>
        <div ref={ref}>{children}</div>
      </div>
    )
  }
)

export default BottomDrawerContent
