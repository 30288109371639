import React, { useMemo } from 'react'

import sanitizeString from 'utils/sanitizeString'

interface UniversalSearchListHighlightableTextProps {
  text: string
  highlight: string
  className?: string
}

const escapeRegex = (regex: string) => regex.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')

const UniversalSearchListHighlightableText = ({
  text,
  highlight,
  className,
}: UniversalSearchListHighlightableTextProps) => {
  const chunks = useMemo(() => {
    const sanitizedText = sanitizeString(text)
    const sanitizedHighlight = sanitizeString(highlight)
    const matchRegex = new RegExp(escapeRegex(sanitizedHighlight), 'g')

    const results: { isHighlighted: boolean; start: number; end: number }[] = []
    let match: RegExpExecArray | null
    let lastIndex = 0

    while ((match = matchRegex.exec(sanitizedText))) {
      const start = match.index
      const end = matchRegex.lastIndex

      if (end > start) {
        results.push({ isHighlighted: false, start: lastIndex, end: start })
        results.push({ isHighlighted: true, start, end })
        lastIndex = end
      }

      if (match.index === matchRegex.lastIndex) matchRegex.lastIndex++
    }

    results.push({ isHighlighted: false, start: lastIndex, end: text.length })

    return results
  }, [text, highlight])

  return (
    <span className={className}>
      {chunks.map(({ isHighlighted, start, end }, i) => {
        const value = text.substring(start, end)

        return isHighlighted ? (
          <mark role="mark" key={i} className="bg-secondary-orange-100">
            {value}
          </mark>
        ) : (
          value
        )
      })}
    </span>
  )
}

export default UniversalSearchListHighlightableText
